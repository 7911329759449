import React from 'react'
import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import { HiddenOnDesktop, HiddenOnMobile } from 'src/utils/styles/global-styles'
import { BuyNowLink } from '../Hero/styles'

import { TextContainer } from '../HomepageHero/styles'
import {
  Heading,
  Container,
  ImageContainer,
  CtaContainer,
  LogoImageContainer,
  SubHeading,
  SyncsHeader,
  SyncsSection,
  Flexbox,
} from './styles'
import { CAL_BUY_LINK } from '../../utils/constants'

export default function HomepageCalHero() {
  const data = useStaticQuery(graphql`
    query HomepageCalHeroQuery {
      calBackground: file(
        relativePath: { eq: "homepage/calendar-on-kitchen-counter-desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calBackgroundMobile: file(relativePath: { eq: "homepage/calendar-on-counter-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      google: file(relativePath: { eq: "icons/google.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      outlook: file(relativePath: { eq: "icons/outlook.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      apple: file(relativePath: { eq: "icons/apple.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cozi: file(relativePath: { eq: "icons/cozi.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      yahoo: file(relativePath: { eq: "icons/yahoo.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const calendarList = [
    { id: 'google', label: 'Google' },
    { id: 'outlook', label: 'Outlook' },
    { id: 'apple', label: 'Apple' },
    { id: 'cozi', label: 'Cozi' },
    { id: 'yahoo', label: 'Yahoo' },
  ]

  return (
    <Container>
      <HiddenOnDesktop>
        <ImageContainer>
          <GatsbyImage
            image={data.calBackgroundMobile.childImageSharp.gatsbyImageData}
            alt='Skylight Calendar 15" on a kitchen countertop with a young daughter looking at it and her mom behind her'
          />
        </ImageContainer>
      </HiddenOnDesktop>
      <TextContainer singleSlide>
        <YotpoStars color={theme.orange} textcolor="#444444" productId="7073751498804" />
        <Heading>Goodbye, Stress. Hello, Organization.</Heading>
        <SubHeading>
          Get the whole family organized with scheduling, meal planning, chore charts and
          customizable lists — all on one beautiful display.
        </SubHeading>
        <Flexbox>
          <CtaContainer>
            <BuyNowLink
              onClick={() => Analytics.track('Clicked Buy Now Calendar Hero on Homepage CTA')}
              to={CAL_BUY_LINK}
            >
              Shop Calendar
            </BuyNowLink>
          </CtaContainer>
          <SyncsSection>
            <SyncsHeader>Seamlessly auto-syncs with:</SyncsHeader>
            <CtaContainer>
              {calendarList.map(({ id, label }) => (
                <LogoImageContainer>
                  <GatsbyImage
                    key={id}
                    image={data[id].childImageSharp.gatsbyImageData}
                    alt={`Skylight Calendar is compatible with ${label}`}
                  />
                </LogoImageContainer>
              ))}
            </CtaContainer>
          </SyncsSection>
        </Flexbox>
      </TextContainer>
      <HiddenOnMobile>
        <ImageContainer>
          <GatsbyImage
            image={data.calBackground.childImageSharp.gatsbyImageData}
            alt='Skylight Calendar 15" on a kitchen countertop with a young daughter looking at it and her mom behind her'
          />
        </ImageContainer>
      </HiddenOnMobile>
    </Container>
  )
}
